import React from 'react';
import { Box, Text, Image, VStack } from '@chakra-ui/react';

const LoadingScreen = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="white"
    >
      <VStack spacing={6}>
        <Text
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
        >
          Loading...
        </Text>
        <Image
          src="/logo.png"
          alt="ReplicaCoin Logo"
          width="80px"
          height="80px"
          objectFit="contain"
        />
      </VStack>
    </Box>
  );
};

export default LoadingScreen;
